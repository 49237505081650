.dialog_col {
    width: 100%;
    height: auto;
    display: flex;
    display: -webkit-flex;

    &.common-w {
        width: calc(100% - 16px);
        margin: 0 auto;
    }

    &.titlebar {
        padding: 8px 0;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .start-dialog-heading-text {
        font-size: 24px;
    }

    .start-dialog-desc-text {
        font-size: 17px;
        max-width: calc(100% - 80px);
        text-align: center;
    }

    .start-dialog-desc-2-text {
        font-size: 16px;
    }

    &.center-layout {
        justify-content: center;
        align-items: center;
    }
}