@import 'include_media';
@import 'vars';
@import 'color_scheme';
@import 'fonts';
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: sans-serif;
}

*:focus {
    outline: none;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;

  overflow: auto;
}

.lcheck {
    border: 2px solid rgba(0, 0, 0, 0.4) !important;
}

.app-wrapper {
    width: 100%;
    height: 100vh;
    max-height: calc(100vh - 10px);
    position: fixed;
    inset: 0;
    background-color: var(--color-bg);

    position: relative;

    &.-mobile-view {
        height: fit-content
    }
}

@import "components/button";
@import "components/checkbox";
@import "components/select";
@import "components/navigationBar";
@import "components/launcher";
@import "components/dialog";
@import "components/sidebar";
@import "components/shortkey";
@import "components/input";
@import "components/popup";

@import "pages/main";
@import "pages/login";
@import "pages/loading";


/** Commons **/

.pd-12 {
    padding: 12px;
}

.pd12-24 {
    padding: 12px 24px;
}

.mb-12 {
    margin-bottom: 12px;
}

.h-12 {
    height: 12px;
}

.h-30 {
    height: 30px;
}


.mb-6 {
    margin-bottom: 6px;
}

.mt-12 {
    margin-top: 12px;
}

.mt-62 {
    margin-top: 62px;
}