.popupMenu {
    position: absolute;
    width: 200px;
    height: auto;
    background-color: var(--color-bg);
    border-radius: 16px;
    max-height: 300px;
    box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.1),
                0 4px 16px -3px rgba(0, 0, 0, 0.21);
    overflow: auto;
    z-index: 10000;

    > .innerPopup {
        width: 100%;
        height: 100%;
        padding: 6px;
    }
}

.popupbackdrop-window {
    position: fixed;
    width: 110vw;
    height: 100vh;
    top: 0;
    right: 0;
    bottom: 0;
    left: -50vw;
    z-index: 9999;
}