// .checkbox-container {

//     display: block;
//     position: relative;
//     cursor: pointer;
//     -webkit-user-select: none;
//     -moz-user-select: none;
//     -ms-user-select: none;
//     user-select: none;

//     padding-left: 40px;

//     &.width_full {
//         cursor: default;
//         width: 100%;
//     }

//     >.label-item {
//         display: flex;
//         display: -webkit-flex;
//         flex-direction: column;
//         justify-content: flex-start;
//         align-items: flex-start;

//         &.--center {
//             position: relative;
//             top: -2px;
//         }

//         >.label {
//             .jsFontStyle {
//                 font-size: 18px;
//                 line-height: 30px;
//             }

//             .fontStyle {
//                 font-size: 17px;
//                 line-height: 30px;
//             }
//         }

//         >.desc {
//             .jsFontStyle {
//                 font-size: 16px;
//                 line-height: 20px;
//             }

//             .fontStyle {
//                 font-size: 15px;
//                 line-height: 20px;
//             }
//         }
//     }

//     input {
//         position: absolute;
//         opacity: 0;
//         cursor: pointer;
//         height: 0;
//         width: 0;
//     }

//     .checkbox-checkmark {
//         position: absolute;
//         top: 0;
//         left: 0;
//         height: 25px;
//         width: 25px;
//         border-radius: 25px;
//         background-color: var(--color-bg-alt);
//         transition: background-color 0.1s, box-shadow 0.04s ease;


//         &::after {
//             content: "";
//             position: absolute;
//             transition: border 0.01s ease;
//         }
//     }

//     &:hover input~.checkbox-checkmark {
//         box-shadow: 0 0 0 1px var(--color-unnamed-3);
//         transform: scale(1.05);
//         transition: transform 0.1s ease;
//     }

//     input:checked~.checkbox-checkmark {
//         // background-color: var(--color-unnamed-5);
//         background-color: rgba(255, 255, 255, 1);
//         box-shadow: 0 0 0 1px var(--color-unnamed-3);
//     }

//     input:disabled~.checkbox-checkmark {
//         // background-color: var(--color-unnamed-5);
//         background-color: var(--color-bg-alt);
//         box-shadow: 0 0 0 1px var(--color-unnamed-3);
//     }

//     &:hover input:checked~.checkbox-checkmark {
//         // box-shadow: 0 0 0 2px rgba(93, 54, 109, 1);
//         transform: scale(1.1);
//     }

//     &:hover input:disabled~.checkbox-checkmark {
//         box-shadow: 0 0 0 1px var(--color-unnamed-3);
//     }

//     &:focus-within {
//         .checkbox-checkmark {
//             box-shadow: 0 0 0 3px var(--color-unnamed-4);
//         }
//     }

//     input:checked~.checkbox-checkmark:after {
//         display: block;

//         // left: 9px;
//         // top: 5px;
//         left: 10px;
//         top: 7px;
//         width: 5px;
//         height: 10px;
//         border: solid var(--color-unnamed-5);
//         border-width: 0 2px 2px 0;
//         -webkit-transform: rotate(45deg);
//         -ms-transform: rotate(45deg);
//         transform: rotate(45deg);
//     }

//     input:disabled~.checkbox-checkmark:after {
//         border: solid var(--color-unnamed-4);
//         border-width: 0 2px 2px 0;
//     }

// }

.checkbox-container {

    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    // padding-left: 40px;
    padding-left: 0;
    width: 100%;

    &.width_full {
        cursor: default;
        width: 100%;
    }

    &:hover {
        .label-item > .label {
            text-decoration: underline;
        }
    }

    >.label-item {
        display: flex;
        display: -webkit-flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        &.--center {
            position: relative;
            top: -2px;
        }

        >.label {
            .jsFontStyle {
                font-size: 18px;
                line-height: 30px;
            }

            .fontStyle {
                font-size: 17px;
                line-height: 30px;
            }
        }

        >.desc {
            .jsFontStyle {
                font-size: 16px;
                line-height: 20px;
            }

            .fontStyle {
                font-size: 15px;
                line-height: 20px;
            }
        }
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .checkbox-checkmark {
        position: absolute;
        top: 0;
        // left: 0;
        right: 0;
        height: 25px;
        width: 25px;
        border-radius: 25px;
        background-color: var(--color-bg-alt);
        transition: background-color 0.1s, box-shadow 0.04s ease;


        &::after {
            content: "";
            position: absolute;
            transition: border 0.01s ease;
        }
    }

    &:hover input~.checkbox-checkmark {
        box-shadow: 0 0 0 1px var(--color-unnamed-3);
        transform: scale(1.05);
        transition: transform 0.1s ease;
    }

    input:checked~.checkbox-checkmark {
        // background-color: var(--color-unnamed-5);
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0 0 0 1px var(--color-unnamed-3);
    }

    input:disabled~.checkbox-checkmark {
        // background-color: var(--color-unnamed-5);
        background-color: var(--color-bg-alt);
        box-shadow: 0 0 0 1px var(--color-unnamed-3);
    }

    &:hover input:checked~.checkbox-checkmark {
        // box-shadow: 0 0 0 2px rgba(93, 54, 109, 1);
        transform: scale(1.1);
    }

    &:hover input:disabled~.checkbox-checkmark {
        box-shadow: 0 0 0 1px var(--color-unnamed-3);
    }

    &:focus-within {
        .checkbox-checkmark {
            box-shadow: 0 0 0 3px var(--color-unnamed-4);
        }
    }

    input:checked~.checkbox-checkmark:after {
        display: block;

        // left: 9px;
        // top: 5px;
        left: 10px;
        top: 7px;
        width: 5px;
        height: 10px;
        border: solid var(--color-unnamed-5);
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    input:disabled~.checkbox-checkmark:after {
        border: solid var(--color-unnamed-4);
        border-width: 0 2px 2px 0;
    }

}


.toggle-container {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    padding-left: 0;
    width: 100%;

    &.width_full {
        cursor: default;
        width: 100%;
    }

    &:hover {
        .label-item > .label {
            text-decoration: underline;
        }
    }

    > .label-item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        &.--center {
            position: relative;
            top: -2px;
        }

        > .label {
            .jsFontStyle {
                font-size: 18px;
                line-height: 30px;
            }

            .fontStyle {
                font-size: 17px;
                line-height: 30px;
            }
        }

        > .desc {
            .jsFontStyle {
                font-size: 16px;
                line-height: 20px;
            }

            .fontStyle {
                font-size: 15px;
                line-height: 20px;
            }
        }
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    .toggle-switch {
        position: absolute;
        top: 0;
        right: 0;
        width: 35px; /* Reduced width */
        height: 18px; /* Reduced height */
        background-color: #CABFCE;
        border-radius: 18px;
        transition: background-color 0.2s;

        &::after {
            content: "";
            position: absolute;
            width: 14px; /* Reduced width */
            height: 14px; /* Reduced height */
            background-color: white;
            border-radius: 50%;
            top: 2px; /* Adjusted for smaller size */
            left: 2px;
            transition: transform 0.2s;
        }
    }

    &:hover input~.toggle-switch {
        box-shadow: 0 0 0 1px var(--color-unnamed-3);
    }

    input:checked~.toggle-switch {
        background-color: var(--color-primary);

        &::after {
            transform: translateX(17px); /* Adjusted translate for smaller size */
        }
    }

    input:disabled~.toggle-switch {
        background-color: var(--color-disabled);

        &::after {
            background-color: var(--color-disabled-bg);
        }
    }

    &:focus-within .toggle-switch {
        box-shadow: 0 0 0 3px var(--color-focus);
    }
}