.inputElement {
    width: 100%;
    height: 44px;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    height: 40px;

    background-color: var(--color-bg);
    box-shadow: 0 0 0 1px var(--color-unnamed-3);
    border-radius: 42px;
    overflow: hidden;

    &.textArea {
        height: 80px;
        border-radius: 16px;
    }

    >div {
        width: 100%;
        height: 100%;
        display: flex;
        display: -webkit-flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        >input {
            width: 100%;
            height: 100%;
            border: none;
            padding: 0 24px;
            font-size: 16px;
            background-color: transparent;
            color: var(--color-bg-inverted);
        }

        > textarea {
            width: 100%;
            height: 100%;
            border: none;
            padding: 14px 24px;
            font-size: 16px;
            background-color: transparent;
            color: var(--color-bg-inverted);
        }

        &.auto {
            width: auto;

            .btnCollect {
                border-radius: 0;
                padding-left: 10px;
            }
        }
    }

    &:focus-within {
        // box-shadow: 0 0 0 1px var(--color-unnamed-3), 0 0 0 4px var(--color-unnamed-1);
        box-shadow: 0 0 0 1px var(--color-primary);
    }
}