.dialog_col {

    &.common-shorts-w {
        width: calc(100% - 40px);
        margin: 0 auto;
    }

    &.input-container {
        width: 100%;
        height: auto;

        > .inner-col {
            width: calc(100% - 100px);
            height: 100%;
            margin: 0 auto;
            display: flex;
            display: -webkit-flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            @include media("<600px") {
                width: calc(100% - 30px);
            }
        }
    }

    &.input-container-title {
        > .inner-col {
            padding-left: 20px;
        }
    }

    &.keywords-container {
        > .inner-col {
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;

            .no-keywords {
                display: block;
                width: 100%;
                opacity: 0.7;
                text-align: center;
            }
        }
    }

    &.buttons-container {
        > .inner-col {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            > button {

                width: 100%;
                margin-right: 30px;

                &:last-child {
                    margin-right: 0;
                }

                > span {
                    &:first-child {
                        width: auto;
                    }
                }
                
            }
        }
    }
}

.skey-dialog-tabs {
    width: 100%;
    display: flex;
    justify-content: center;

    .tab-container {
        width: fit-content;
        height: 40px;
        background-color: #eeeeee;
        border-radius: 40px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 4px;

        .tab {
            padding: 0 20px;
            width: fit-content;
            text-align: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-radius: 40px;
            font-size: 14px;

            &:hover {
                background-color: #ffffff4d;
            }

            &.active {
                background-color: #ffffff;
            }
        }
    }
}