.sidebarWidth {
  width: 60vw !important;
  min-width: 500px !important;
  max-width: 600px !important;
  right: 0 !important;
  top: 10px !important;

  @include media("<500px") {
    width: 100vw !important;
    min-width: 100vw !important;
  }
}

.sidebar-wrapper {
  width: 60vw;
  max-width: 600px;
  min-width: 500px;
  height: 100vh;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: var(--color-bg);

  // right: -100%;
  opacity: 0;
  overflow: hidden;

  right: 0;
  transform: translateX(100%);
  transition: transform 0.4s ease;

  // transition: right 0.3s ease;

  &.sidebar-active {
    right: 0;
    opacity: 1;
    overflow: visible;
    transform: translateX(0);
  }

  &.n-sidebar-hidden {
    right: 0;
    transform: translateX(100%) !important;
}


  @include media("<500px") {
    width: 100vw;
    min-width: 100vw;
  }

  --inner-width: calc(100% - 50px);

  .spacer {
    width: 100%;
    display: block;

    &.mb-view-adaption {
      @include media("<680px") {
        padding-top: 80px;
      }
    }

    &.-seperator {
      width: calc(100% - 80px);
      height: 1px;
      margin: 24px auto;
      background-color: rgba($color: #000000, $alpha: 0.1);
    }
  }

  > div {
    width: 100%;
    height: 100%;

    overflow-y: auto;
    position: relative;

    > .header {
      width: 100%;
      height: auto;
      position: relative;

      // position: sticky;
      // position: -webkit-sticky;
      // top: 0;
      position: fixed;
      z-index: 10000;

      background-color: #fff;

      height: 67px;
      top: 0 !important;

      border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);

      > .inner-header {
        // width: var(--inner-width);
        width: calc(100% - 20px);
        margin: 0 auto;
        height: 100%;
        display: flex;
        display: -webkit-flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 12px;

        .row {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
          gap: 8px;

          &:first-child {
            width: fit-content;
          }

          .sdSearchbar {
            width: 100%;
            height: 44px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            background-color: var(--color-bg-alt2);
            // box-shadow: 0 0 0 1px var(--color-unnamed-3);
            border-radius: 42px;
            overflow: hidden;

            > div {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;

              input {
                width: 100%;
                height: 100%;
                border: none;
                padding: 0 12px;
                padding-left: 20px;
                font-size: 16px;
                background-color: transparent;
              }

              &.auto {
                width: auto;
              }
            }
          }
        }

        .tabSwitches {
          width: max-content;
          min-width: 88px;
          padding: 4px;
          background-color: var(--color-bg-alt2);
          border-radius: 40px;
          // position: absolute;
          // left: 50%;
          // top: 20px;
          // transform: translateX(-50%);

          > button {
            &.active {
              background-color: var(--color-bg);
            }
          }
        }
      }
    }

    .sidebar-content {
      width: 100%;
      height: calc(100% - 40px);

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
    }

    .sidebar-body {
      width: 100%;
      height: auto;
    }

    .col {
      width: 100%;
      height: auto;

      > .inner-col {

        margin-left: 40px;
        margin-right: 40px;

        &.width_full {
          margin-left: 40px;
        }

        // width: var(--inner-width);
        height: 100%;
        // margin: 0 auto;
        display: flex;
        display: -webkit-flex;
      }

      &.align-options-in-right {
        .inner-col {
          margin-right: 20px;
        }
      }
    }

    .sidebarSearchbarContainer {
      > div {
        .sdSearchbar {
          width: 100%;
          height: 44px;
          // height: 56px;
          margin-bottom: 10px;
          display: flex;
          display: -webkit-flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          background-color: var(--color-bg);
          box-shadow: 0 0 0 1px var(--color-unnamed-3);
          border-radius: 42px;
          overflow: hidden;

          > div {
            width: 100%;
            height: 100%;
            display: flex;
            display: -webkit-flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            > input {
              width: 100%;
              height: 100%;
              border: none;
              padding: 0 24px;
              font-size: 16px;
              background-color: transparent;
              color: var(--color-bg-inverted);
            }

            &.auto {
              width: auto;

              .btnCollect {
                border-radius: 0;
                padding-left: 10px;
              }
            }
          }

          &:focus-within {
            box-shadow: 0 0 0 1px var(--color-primary);
            // box-shadow: 0 0 0 1px var(--color-unnamed-3), 0 0 0 4px var(--color-unnamed-1);
          }
        }
      }
    }

    .sidebarTabsContainer {
      > div {
        .sdTabs {
          width: 100%;
          height: 40px;
          padding: 4px;
          background-color: var(--color-bg-alt2);
          border-radius: 40px;
          display: flex;
          display: -webkit-flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          > button {
            background-color: transparent;
            box-shadow: none;
            width: 100%;
            height: 100%;
            &.active {
              background-color: var(--color-bg);
            }
          }
        }
      }
    }

    // .floating-selection {
    //   position: absolute;
    //   // bottom: 40px;
    //   // bottom: 0;
    //   background-color: red;
    //   border-bottom: 10px solid red;
    //   // width: 50%;
    //   width: 100%;
    //   // max-width: 400px;
    //   background-color: #ffffff;
    //   border-top: 1px solid rgba($color: #000000, $alpha: 0.07);
    //   // background-color: rgba(179, 179, 179, 0.1);
    //   // height: 100px;
    //   // backdrop-filter: blur(14px);
    //   // border-radius: 12px;
    //   // margin-left: 50%;
    //   // transform: translateX(-50%);

    //   .inner {
    //     width: 100%;
    //     height: 100%;
    //     display: flex;
    //     flex-direction: row;
    //     justify-content: center;
    //     align-items: center;
    //     gap: 24px;
    //     background-color: red;


    //     .new-buttonx-style {
    //       padding: 0 30px;
    //       height: 40px;
    //       background-color: #ffffff;
    //       box-shadow: inset 0 0 0 2px $color-primary;
    //       border-radius: 40px;
    //       font-size: 15px;
    //       color: $color-primary;

    //       transition: 0.2s ease;

    //       &.filled {
    //         background-color: $color-primary;
    //         color: #ffffff;
    //       }

    //       &:hover {
    //         opacity: 0.7;
    //       }
    //     }
    //   }
    // }

    .footer {

      @include media("<680px") {
        padding-bottom: 80px;
        padding-top: 80px;
      }

      > div {
        flex-direction: row;
        justify-content: center;
        align-items: center;

        > a {
          margin-right: 6px;
          margin-left: 6px;
          font-size: 14px;
          color: var(--color-primary);

          &:last-child {
            margin-right: 0;
          }

          &:first-child {
            margin-left: 0;
          }
        }

        @include media("<450px") {
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-end;

          > a {
            margin: 0;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding: 12px 4px;

            border-bottom: 1px solid var(--color-unnamed-7);
          }
        }
      }
    }

    .shortkeysContainer {
      > div {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }
    }

    .accountInfo {
      > div {
        flex-direction: row;
        justify-content: center;
        align-items: center;

        > img {
          width: 100px;
          height: 100px;
          border-radius: 100px;
        }

        > span {
          font-weight: 400;
          font-size: 18px;
          color: var(--color-bg-inverted);
        }
      }
    }

    > .accountOptions {
      > div {
        flex-direction: row;
        justify-content: center;
        align-items: center;

        > button {
          margin: 24px;
          width: 100%;

          &:first-child {
            margin-right: 12px;
            margin-left: 0;
          }

          &:last-child {
            margin-left: 12px;
            margin-right: 0;
          }
        }
      }
    }
  }


  .floating-selection {
    // position: absolute;
    // // bottom: 40px;
    // // bottom: 0;
    // background-color: red;
    // border-bottom: 10px solid red;
    // // width: 50%;
    // width: 100%;
    // // max-width: 400px;
    // background-color: #ffffff;
    border-top: 1px solid rgba($color: #000000, $alpha: 0.07);
    // // background-color: rgba(179, 179, 179, 0.1);
    // // height: 100px;
    // // backdrop-filter: blur(14px);
    // // border-radius: 12px;
    // // margin-left: 50%;
    // // transform: translateX(-50%);

    // bottom: 20px;
    bottom: 0;
    position: absolute;
    width: 100%;
    display: block;
    z-index: 999999999999999999;
    // bottom: 0;
    height: fit-content;
    padding-bottom: 20px;
    background-color: #ffffff;

    // padding-bottom: 100px;

    &.yes-mobile {
      top: calc(100vh - 160px);
      position: absolute;
      width: 100%;
      display: block;
      z-index: 999999999999999999;
      // bottom: 0;
      height: 160px;
      background-color: #ffffff;  
    }

    .inner {
      width: 100%;
      // height: 100%;
      padding-top: 20px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 24px;
      background-color: transparent;


      .new-buttonx-style {
        padding: 0 30px;
        height: 40px;
        background-color: #ffffff;
        box-shadow: inset 0 0 0 2px $color-primary;
        border-radius: 40px;
        font-size: 15px;
        color: $color-primary;

        transition: 0.2s ease;

        &.filled {
          background-color: $color-primary;
          color: #ffffff;
        }

        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
  
}

.graybtn {
    width: auto;
    height: auto;
    display: flex;
    padding: 6px 20px;
    border-radius: 16px;
    color: rgba($color: #000000, $alpha: 0.5);
    font-size: 15px;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        background-color: rgba($color: #000000, $alpha: 0.07);
        text-decoration: none;
    }
}