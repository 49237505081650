.launcherArea {

    width: 100%;
    height: auto;
    transition: margin 0.4s ease;

    &.--allow-scroll {
        overflow: auto !important;
    }

    &.-mobile-view {
        display: none;




        margin: 0;
        margin-top: 16px;
    }

    &._inMainPage {
        // margin-top: 12vh;
        margin-top: calc(12vh + 64px);

        
        // height: calc(100vh - (40px + 24px) - 20px);
        &.-mobile-view {
            margin: 0;
            margin-top: 16px;
        }
    }

    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logoContainer {

        margin-bottom: 20px;

        >img {
            // width: 50vw;
            // max-width: 500px;
            // min-width: 300px;

            width: 25vw;
            max-width: 340px;
            min-width: 300px;
        }

        // @include media("<350px") {
        //     >svg {
        //         min-width: 220px;
        //     }
        // }
    }

    &.-mobile-view {
        .logoContainer {
            // display: none;
            opacity: 0;
            transition: 0.2s ease;
        }

        &.-stage-1 {
            .logoContainer {
                display: none;
            }
        }
    }

    .shortsLauncherInputContainer {
        width: calc(100% - 40px);
        max-width: 600px;

        height: 50px;
        // height: auto;
        // background-color: var(--color-bg);
        // border: 1px solid var(--color-unnamed-3);
        background-color: #F2F2F2;

        border-radius: 48px;

        max-height: 300px;

        > .innerShortsLauncherInputContainer {
            // width: calc(100% - 40px);
            width: calc(100% - 6px);
            margin: 0 auto;
            height: 100%;

            display: flex;
            display: -webkit-flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            position: relative;

            &.initialInputContainer {

                > span {

                    &.innerInputContainer {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;

                        // &.activeMultiline {
                        //     align-items: flex-start;
                        // }

                        .active-search-left-padding {
                            display: block;
                            width: 12px;
                        }

                        .input-control {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;

                            padding: 0 20px;
                            min-height: 50px;

                            max-height: 280px;
                            overflow: auto;

                            span {
                                width: 100%;
                                display: block;
                                // text-wrap: nowrap;
                                // white-space: nowrap;
                                font-size: 16px;
                            }

                            .placeholder {
                                position: absolute;
                                opacity: 0.5;
                                pointer-events: none;
                            }
                        }

                        .active-search-close-icon-container {
                            width: auto;
                            height: 100%;
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-end;
                            align-items: center;
                        }
                    }

                }

                .searchEngineSelector {
                    cursor: text;

                    > span {

                        &.matched_keyword {
                            opacity: 0.4;
                        }

                        font-size: 16px;
                        color: var(--color-bg-inverted);

                        // &:first-child {
                        //     &:hover + &.hoverBox {
                        //         background-color: var(--color-unnamed-3);
                        //     }
                        // }

                        &:first-child {
                            z-index: 2;
                            position: relative;
                            cursor: pointer;
                        }

                        &:first-child:hover + .hoverBox {
                            background-color: var(--color-unnamed-3);
                        }

                        &.hoverBox {
                            z-index: 1;
                            background-color: rgba(0, 0, 0, 0);
                            border-radius: 20px;
                            padding: 4px 6px;
                            color: transparent;
                            position: absolute;
                            left: -6px;
                            top: 50%;
                            transform: translateY(-50%);

                            &.active {
                                background-color: var(--color-unnamed-3);
                            }
                        }

                        &:last-child {
                            z-index: 1;
                            display: block;
                            position: absolute;
                            width: 100%;
                            height: 16px;
                            top: 50%;
                            transform: translateY(-60%);
                            left: 0;
                            right: 0;
                            // border: 1px solid;
                            background-color: rgba(0, 0, 0, 0);
                        }

                        &.transparent {
                            color: transparent;
                            caret-color: var(--color-primary);
                        }

                    }
                }
            }
        }

        &.activeMultiline {
            height: auto;
            min-height: 48px;
            border-radius: 12px;
            padding: 8px 0;

            > .innerShortsLauncherInputContainer {
                &.initialInputContainer {

                    > span {
    
                        &.innerInputContainer {
                            align-items: flex-start;
                        }
                    }
                }
            }
        }
    }

    .categoriesContainer {
        width: calc(100% - 40px);
        
        max-width: 600px;

        height: auto;
        margin-top: 20px;

        >div {
            width: 100%;
            height: 100%;

            display: -webkit-flex;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            > .categories-title {
                width: 100%;
                padding-bottom: 20px;

                > div {
                    width: 100%;
                    height: auto;

                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 14px;

                    button, span {
                        pointer-events: all;
                        font-size: 15px;
                        color: #5D366D;
                        display: flex;

                        svg {
                            mask {
                                rect {
                                    fill: #5D366D !important;
                                }
                            }
                            g {
                                path {
                                    fill: #5D366D !important;
                                }
                            }
                        }
                    }
                }
            }
        
            > .categories-list {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 10px;
                flex-wrap: wrap;

                .cat-btn {
                    padding: 8px 16px;
                    background-color: #F8F8F8;
                    color: #5D366D;
                    font-size: 15px;
                    border-radius: 40px;
                }
            }

            > .link-list {
                width: 100%;
                .shortsSuggestion {
                    width: 100%;
                    height: 40px;
                    border-radius: 40px;
                    cursor: pointer;
    
                    >div {
                        // width: calc(100% - 30px);
                        width: 100%;
                        height: 100%;
    
                        @include media("<400px") {
                            width: 100%;
                        }
    
                        display: flex;
                        display: -webkit-flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
    
                        padding: 0 16px;
    
                        >div {
                            width: fit-content;
                            height: 100%;
                            display: flex;
                            display: -webkit-flex;
                            flex-direction: row;
                            justify-content: flex-start;
                            align-items: center;
                            overflow: hidden;
                            text-overflow: ellipsis;
    
                            > svg {
                                margin-right: 10px;
                            }
        
                            >span {
    
                                &:first-child {
                                    margin-right: 12px;
                                    font-size: 17px;
                                    font-family: 'Inter sans', sans-serif;
                                    // font-family: 'Josefin sans', sans-serif;
                                    white-space: nowrap;
                                    // max-width: calc(40% + 12px);
                                    max-width: max-content;
                                    width: fit-content;
                                    margin-right: 0;
                                    overflow: hidden;
                                    color: var(--color-bg-inverted);
                                    text-overflow: ellipsis;
                                }
    
                                // &:last-child {
                                //     max-width: calc(100% - (40% + 24px));
                                //     border-radius: 32px;
                                //     padding: 4px 12px;
                                //     color: var(--color-bg-inverted);
                                //     opacity: 0.3;
                                //     font-size: 16px;
                                //     font-family: 'Josefin sans', sans-serif;
                                //     white-space: nowrap;
                                //     overflow: hidden;
                                //     text-overflow: ellipsis;
                                // }
    
                            }
    
                            &.auto {
                                width: fit-content;
                                color: #5D366D;

                                span {
                                    color: #5D366D;
                                }
    
                                @include media("<400px") {
                                    width: 0;
    
                                    > button {
                                        display: none;
                                    }
                                }
    
                                > button {
                                    opacity: 0;
                                    &:hover {
                                        background-color: transparent;
                                    }
                                }
                            }
                        }
                    }
    
                    &.active {
                        background-color: var(--color-bg-alt2);
    
                        >div {
                            > div {
                                &.auto {
                                    > button {
                                        opacity: 0.4;
                                    }
                                }
                            }
                        }
                    }
                }
            }

        }
    }

    .suggestionsContainer {
        width: calc(100% - 40px);
        
        max-width: 600px;

        height: auto;
        margin-top: 10px;

        >div {
            width: 100%;
            height: 100%;

            display: -webkit-flex;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            .ai-result {
                width: 100%;
                background-color: #F9F9F9;
                color: #5D366D;
                padding: 16px;
                border-radius: 16px;
                position: relative;

                .keymo {
                    position: absolute;
                    left: -40px;
                    top: 0;
                    font-size: 9px;

                    svg {
                        width: 28px;
                    }
                }

                .start-followup {
                    display: inline-block;
                    font-size: 12px;
                    border: 1px solid rgba($color: #000000, $alpha: 0.1);
                    border-radius: 4px;
                    padding: 2px 4px;
                    margin-left: 4px;
                    width: fit-content;
                }
            }

            .ai-result-follow-up {
                width: 100%;
                background-color: #F9F9F9;
                color: #5D366D;
                padding: 16px;
                border-radius: 16px;
                position: relative;
                margin-top: 12px;

                &.-assistant {
                    border: 1px solid rgba($color: #000000, $alpha: 0.08);
                    background-color: transparent;
                }

                &.-user {
                    border-radius: 40px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    .edit-icon {
                        opacity: 0.6;
                        cursor: pointer;

                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }

                &.-input {
                    background-color: #F2F2F2;
                    height: 50px;
                    border-radius: 48px;
                    padding: 0;
                    padding: 0 20px;

                    span {
                        display: flex;
                        width: 100%;
                        height: 100%;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        font-size: 16px;
                        line-height: 50px;
                        white-space: nowrap;
                        overflow: auto;
                    }
                }
            }

            .keySuggestion {
                width: 100%;
                height: 40px;
                border-radius: 40px;
                cursor: pointer;

                // &:hover,
                &.active {
                    background-color: var(--color-bg-alt2);
                }

                >div {
                    width: 100%;
                    height: 100%;

                    display: flex;
                    display: -webkit-flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;

                    padding: 0 16px;

                    // overflow: hidden;
                    // text-overflow: ellipsis;

                    > svg {
                        min-width: 24px;
                    }

                    >span {

                        margin-left: 8px;   /** for icon */
                        white-space: nowrap;
                        font-size: 17px;
                        max-width: calc(100% + 12px);
                        overflow: hidden;
                        text-overflow: ellipsis;

                        &:first-child {
                            margin-right: 12px;
                            font-size: 17px;
                            // font-family: 'Josefin sans', sans-serif;
                            white-space: nowrap;
                            // max-width: calc(40% + 12px);
                            max-width: 100%;
                            width: 100%;
                            margin-right: 0;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            color: var(--color-bg-inverted);
                            margin-left: 8px;   /** for icon */
                        }

                        // &:last-child {
                        //     max-width: calc(100% - (40% + 24px));
                        //     background-color: var(--color-primary);
                        //     border-radius: 32px;
                        //     padding: 4px 12px;
                        //     color: var(--color-bg);
                        //     font-size: 16px;
                        //     font-family: 'Josefin sans', sans-serif;
                        //     white-space: nowrap;
                        //     overflow: hidden;
                        //     text-overflow: ellipsis;
                        // }

                    }
                }
            }

            .search-public-widgets {
                width: 100%;
                height: auto;
                border: 1px solid rgba($color: #000000, $alpha: 0.08);
                margin-bottom: 4px;
                border-radius: 16px;

                iframe {
                    width: 100%;
                    max-height: 600px;
                    border-radius: 16px;
                }
            }

            .search-inbuilt-widgets {
                width: 100%;
                height: auto;
                height: 40px;

                &.calc-result {
                    > .inner {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                    }
                }

                > div {
                    width: 100%;
                    height: 100%;

                    @include media("<400px") {
                        width: 100%;
                    }

                    display: flex;
                    display: -webkit-flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;

                    padding: 0 16px;

                    >div {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        display: -webkit-flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            .shortsSuggestion {
                width: 100%;
                height: 40px;
                border-radius: 40px;
                cursor: pointer;

                >div {
                    // width: calc(100% - 30px);
                    width: 100%;
                    height: 100%;

                    @include media("<400px") {
                        width: 100%;
                    }

                    display: flex;
                    display: -webkit-flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;

                    padding: 0 16px;

                    >div {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        display: -webkit-flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        > svg {
                            margin-right: 10px;
                        }
    
                        >span {

                            &:first-child {
                                margin-right: 12px;
                                font-size: 17px;
                                font-family: 'Inter sans', sans-serif;
                                // font-family: 'Josefin sans', sans-serif;
                                white-space: nowrap;
                                // max-width: calc(40% + 12px);
                                max-width: 100%;
                                width: 100%;
                                margin-right: 0;
                                overflow: hidden;
                                color: var(--color-bg-inverted);
                                text-overflow: ellipsis;
                            }

                            // &:last-child {
                            //     max-width: calc(100% - (40% + 24px));
                            //     border-radius: 32px;
                            //     padding: 4px 12px;
                            //     color: var(--color-bg-inverted);
                            //     opacity: 0.3;
                            //     font-size: 16px;
                            //     font-family: 'Josefin sans', sans-serif;
                            //     white-space: nowrap;
                            //     overflow: hidden;
                            //     text-overflow: ellipsis;
                            // }

                        }

                        &.auto {
                            width: 30px;

                            @include media("<400px") {
                                width: 0;

                                > button {
                                    display: none;
                                }
                            }

                            > button {
                                opacity: 0;
                                &:hover {
                                    background-color: transparent;
                                }
                            }
                        }
                    }
                }

                &.active {
                    background-color: var(--color-bg-alt2);

                    >div {
                        > div {
                            &.auto {
                                > button {
                                    opacity: 0.4;
                                }
                            }
                        }
                    }
                }
            }

            .suggestionButtonContainer {
                width: 100%;
                height: auto;
                display: flex;
                display: -webkit-flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }
            
            .searchEngineItem {
                width: 100%;
                height: 40px;
                border-radius: 40px;
                cursor: pointer;

                >div {
                    width: 100%;
                    height: 100%;

                    display: flex;
                    display: -webkit-flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;

                    padding: 0 16px;

                    >div {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        display: -webkit-flex;
                        flex-direction: row;
                        justify-content: flex-start;
                        align-items: center;

                        gap: 8px;
    
                        >span {

                            &:first-child {
                                display: flex;
                                display: -webkit-flex;
                                flex-direction: row;
                                justify-content: center;
                                align-items: center;
                            }

                            &:last-child {
                                // max-width: calc(100% - (40% + 24px));
                                border-radius: 32px;
                                padding: 4px 4px;
                                color: var(--color-bg-inverted);
                                opacity: 0.3;
                                font-size: 16px;
                                font-family: 'Inter sans', sans-serif;

                                // font-family: 'Josefin sans', sans-serif;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }

                        }

                        &.auto {
                            width: fit-content;
                        
                            &._iconsContainer {
                                @include media("<400px") {
                                    width: 0;
    
                                    > button {
                                        display: none;
                                    }
                                }
                                > button {
                                    opacity: 0;
                                    &:hover {
                                        background-color: transparent;
                                    }
                                }
                            }
                        }
                    }

                    .onlyOnHover {
                        display: none;
                    }

                }

                &:hover {
                    background-color: var(--color-bg-alt2);

                    .hideOnHover {
                        display: none;
                    }

                    .onlyOnHover {
                        display: flex;
                        display: -webkit-flex;
                    }

                    >div {
                        > div {
                            &.auto {
                                > button {
                                    opacity: 0.4;
                                }
                            }
                        }
                    }
                }
            }

        }
    }

}

.early-suggestion {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;
}


.skey-mobile-view-launcher {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100vw;
    height: 100vh;
    background-color: #ffffff;
    z-index: 999999;

    display: flex;

    display: none;

    &.-mobile-view {
        display: flex;
    }
    
    &.launcherArea {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        transform: translateY(-16px);
        padding-top: 24px;
    }
}

.grammar-mode-tool, .calc-copy-btn {
    // position: absolute;
    // right: 8px;
    // top: 8px;
    cursor: pointer;

    svg {
        width: 16px;
        display: inline-block;
        margin-left: 10px;
    }
}