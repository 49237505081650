.dialog-wrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;
    inset: 0;
    background-color: var(--color-unnamed-6);
    z-index: 999;
    overflow: auto;
    transform: scale(1);

    z-index: 999999999999;

    transition: transform 0.2 ease;

    &.hidden-sidebar {
        display: none;
        transform: scale(0);
    }

    >.dialog-box {
        width: 60vw;
        min-width: 500px;
        height: auto;
        position: absolute;
        left: 50%;
        top: 20%;
        // margin-top: 80px;
        // margin-bottom: 80px;
        // height: 400vh;
        transform: translate(-50%, 0);
        background-color: var(--color-bg);
        border-radius: 36px;

        &.dStart {
            top: 10vh;
            max-width: 400px;
        }

        &.dCookies {
            top: 10vh;
            max-width: 400px;
        }

        &.dPrivacy {
            top: 10vh;
            max-width: 400px;
        }

        &.dTerms {
            top: 10vh;
            max-width: 400px;
        }

        &.dAddShort, &.dAddSearchEngine {
            top: 10vh;
            width: 600px;
            max-width: 600px;

            @include media("<680px") {
                width: 100%;
                max-width: 100%;
            }
        }

        @include media("<680px") {
            width: 100vw;
            min-width: 100vw;
            height: 100%;
            border-radius: 0;
            inset: 0;
            top: 0 !important;
            transform: none;
        }

        >div {
            width: 100%;
            height: 100%;

            .footer-buttons {
                width: calc(100% - 100px);
                margin: 0 auto;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                gap: 16px;

                button {
                    width: 120px;
                }
            }
        }

    }
}

.dialogTabs {
    width: auto;
    height: 40px;
    padding: 4px;
    background-color: var(--color-bg-alt2);
    border-radius: 40px;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    >button {
        background-color: transparent;
        box-shadow: none;
        width: 100%;
        height: 100%;

        &.active {
            background-color: var(--color-bg);
        }
    }
}

@import "../tabs/start.dialog";
@import "../tabs/shorts.dialog";