.navigationBar {
    width: 100%;
    height: auto;
    padding: 9px;

    position: absolute;

    >div {
        width: 100%;
        height: 100%;

        display: flex;
        display: -webkit-flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;


        >div {
            width: fit-content;
            height: 100%;

            display: flex;
            display: -webkit-flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            &.alignEnd {
                justify-content: flex-end;
            }

            .navigationBarItemDivider {
                display: inline-flex;
                display: -webkit-inline-flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                height: 100%;
                // margin: 0 8px;

                margin: 0px;

                .divider {
                    width: 2px;
                    height: 20px;
                    background-color: #616161;
                    opacity: 0.1;
                }
            }
        }
    }

    @include media("<400px") {

        > div {
            > div {
                &.mobileResponsiveView {
                    width: calc(100% + 40px);
                    justify-content: space-between;
                    >.navigationBarItemDivider {
                        display: none;
                    }
                }
            }
        }
        
    }
}

.new-button-appearence {
    // display: block;
    // background-color: #F9F9F9;
    // border-radius: 24px;
    // height: 100%;
    // width: fit-content;
    // height: 48px;

    width: max-content;
    min-width: 88px;
    padding: 4px;
    background-color: var(--color-bg-alt2);
    border-radius: 40px;

    &.not-new {
        width: fit-content;
        min-width: fit-content;
        height: 48px;
    }

    .buttonIcon {
        // height: 50px;
        // width: 50px;
        width: 40px;
        height: 40px;
    }
}

.navigation-searchbar {
    display: flex;
    background-color: #F9F9F9;
    border-radius: 24px;
    height: 50px !important;
    width: 100% !important;
}

.gap-8p {
    display: flex;
    gap: 0 4px;
}

.popup-box-bk {
    position: absolute;
    // width: 0;
    height: 0;
    // right: 160px;
    right: 124px;
    // top: 20px;
    top: 28px;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
    // box-shadow: 0 8px 30px -2px rgba(0, 0, 0, 0.2), 0 0 0 1px rgba(0, 0, 0, 0.1);
    opacity: 0;
    pointer-events: none;
    transition: width 0.3s ease, height 0.3s ease, opacity 0.3s ease;

    &.small-one {
        height: 140px;
        // width: 400px;
    }

    @include media("<600px") {
        right: 0;
        top: 0;
        border-radius: 0;
        border: none;
        z-index: 9999999;
    }

    &.show {
        // width: 400px;
        // height: 600px;

        height: 120px;
        width: fit-content;
        background-color: #ffffff;

        opacity: 1;
        pointer-events: auto;

        @include media("<600px") {
            width: 100vw;
            height: 100vh;
        }
    }

    > .inner {
        width: 100%;
        height: 100%;
        padding: 0 10px;

        iframe {
            width: 100%;
            height: 100%;
        }

        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .close_btttn {
            display: none;
            position: fixed;
            right: 4px;
            top: 4px;
        }

        @include media("<600px") {
            justify-content: center;
            gap: 24px;
            .close_btttn {
                display: block;
            }
        }

        @include media("<400px") {
            justify-content: center;
            gap: 4px;
        }

        .item {

            padding: 0 10px;
            display: flex;
            flex-direction: column;
            gap: 4px;
            text-decoration: none;
            color: #1c1c1c;

            > div {
                width: 100%;
                height: auto;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                >  img {
                    width: 60px;
                    height: 60px;
                }

                text-align: center;
                span {
                    font-size: 13px;
                }
            }
        }
    }
}