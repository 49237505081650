.loadingWrapper {
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 1);

    >.loading {
        position: absolute;
        top: 30%;
        left: 50%;
        transform: translateX(-50%);
    }
}

.sk-ldr {
    height: fit-content;
    padding-bottom: 10px;
    
    >.line {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 15px;
        background-color: rgba(var(--color-bg-inverted), 1);
        margin-right: 0.5px;
        margin-left: 0.5px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }

        &:nth-last-child(1) {
            animation: loading2 0.6s 0.1s linear infinite;
        }

        &:nth-last-child(2) {
            animation: loading2 0.6s 0.2s linear infinite;
        }

        &:nth-last-child(3) {
            animation: loading2 0.6s 0.3s linear infinite;
        }
    }

    &.--white {
        >.line {
            background-color: var(--color-bg);
        }
    }

    &.--purple {
        >.line {
            background-color: var(--color-unnamed-5);
        }
    }

    &.--big {
        >.line {
            width: 8px;
            height: 8px;
        }
    }
}


@keyframes loading2 {
    0% {
        transform: translate(0, 0);
    }

    50% {
        transform: translate(0, 15px);
    }

    100% {
        transform: translate(0, 0);
    }
}