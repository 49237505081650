body {
    // background-color: #F9FAFB;
    background-color: #ffffff;
}

.login-form {
    width: 450px;
    padding: 30px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    font-family: Arial, sans-serif;
  
    .input-field {
      width: 100%;
      padding: 10px 16px;
      margin-bottom: 15px;
      border-radius: 40px;
    //   border: 1px solid #ccc;
      font-size: 16px;
      color: #333;
      background-color: #f0f0f0;
    }
  
    .options {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      a, button {
        font-size: 14px;
        color: #333;
        text-decoration: none;
        background: none;
        border: none;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  
    .login-button {
      display: flex;
      justify-content: center;
    //   button {
    //     width: 100%;
    //     padding: 12px;
    //     background-color: #53287f;
    //     color: white;
    //     border: none;
    //     border-radius: 10px;
    //     font-size: 16px;
    //     cursor: pointer;
    //   }
    }
  
    .signup {
      text-align: center;
      margin-top: 20px;
      p {
        font-size: 14px;
        a {
          color: #333;
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }