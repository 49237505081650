.buttonIcon {
    width: 40px;
    height: 40px;
    border: none;
    background: transparent;
    border: none;
    border-radius: 40px;
    cursor: pointer;

    &:disabled {
        opacity: 0.4;
        pointer-events: none;
    }

    display: inline-flex;
    display: -webkit-inline-flex;

    transition: background-color 0.1s ease,
                box-shadow 0.06s ease;

    > span {
        width: 100%;
        height: 100%;

        display: flex;
        display: -webkit-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
    }

    &:hover {
        background-color: #ffffff;
    }

    &:focus {
        // box-shadow: 0 0 0 3px var(--color-unnamed-1);
    }

    &.btnIconSM {
        width: 30px;
        height: 30px;
        background-color: rgba(0, 0, 0, 0.1);

        svg {
            transform: scale(0.8);
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.2);
        }

        &:focus {
            box-shadow: none;
        }
    }
}

.buttonText {
    width: auto;
    height: 48px;
    border: none;
    background: var(--color-primary);
    border: none;
    padding: 0 20px;
    border-radius: 48px;
    cursor: pointer;

    display: inline-flex;
    display: -webkit-inline-flex;

    transition: background-color 0.1s ease,
                box-shadow 0.06s ease;

    > span {
        width: 100%;
        height: 100%;

        display: flex;
        display: -webkit-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: var(--color-bg);

        font-size: 16px;

        &.jsText {
            // font-family: 'Josefin sans', sans-serif;
            // font-size: 18px;
        }
    }

    &.secondaryButton {
        // background-color: var(--color-bg);
        background: rgba(84, 46, 96, 0.1);
        > span {
            color: var(--color-text);
        }
    }

    &:hover {
        background: rgba(84, 46, 96, 0.8);

        &.secondaryButton {
            background: rgba(84, 46, 96, 0.1);
        }
    }

    &:focus {
        box-shadow: 0 0 0 3px var(--color-unnamed-1);
    }
}

a {
    text-decoration: none;

    &.underline {
        text-decoration: underline;
    }

    &:hover {
        text-decoration: underline;
    }
}


.button {
    width: auto;
    height: 44px;
    border: none;
    // background: var(--color-primary);
    background-color: var(--color-bg);
    box-shadow: 0 0 0 1px var(--color-unnamed-3);
    border: none;
    padding: 0 20px;
    border-radius: 42px;
    cursor: pointer;

    display: inline-flex;
    display: -webkit-inline-flex;

    &.popupButton {
        width: 100%;
        box-shadow: none;
        border-radius: 12px;

        &.fullRadius {
            border-radius: 40px;
        }

        > span {
            &.icon {
                margin-right: 16px;
            }
            &:not(.icon) {
                justify-content: flex-start;
            }
        }
    }

    transition: background-color 0.1s ease,
                box-shadow 0.06s ease;
    
    &:disabled {
        opacity: 0.4;
    }

    > span {
        width: 100%;
        height: 100%;

        display: flex;
        display: -webkit-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        color: var(--color-primary);

        white-space: nowrap;

        font-size: 16px;

        &.jsText {
            font-family: 'Josefin sans', sans-serif;
            font-size: 18px;
        }

        &.delete-warning {
            color: rgb(255, 119, 119);
        }

        &:first-child {
            margin-right: 12px;
        }

        &.icon {
            width: auto;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &.centerContent {
        justify-content: center;
        > span {
            width: auto;
        }   
    }

    &:hover {
        background: var(--color-unnamed-2);
    }

    &:focus {
        box-shadow: 0 0 0 3px var(--color-unnamed-1);
    }
}

a {
    
    &.default-link {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        color: var(--color-primary);
        gap: 8px;
    }

    &.bg-link {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        color: var(--color-primary);
        padding: 5px 16px;
        border-radius: 40px;
        gap: 8px;
        text-decoration: none;

        &:hover {
            background-color: var(--color-bg-alt);
        }
    }
}