.mainContentWrapper {
    width: 100%;
    // height: calc(100% - (40px + 24px));
    overflow: auto;
    
    height: 100%;

    > .mainContentHolder {
        width: 100%;
        height: auto;
        /** don't put height = 100% here */
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .mainContentFooter {
        position: absolute;
        bottom: 60px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.floating-collect-btn {
    position: fixed;
    width: 48px;
    height: 48px;
    border-radius: 48px;
    background-color: rgba(84, 46, 96, 1);
    right: 20px;
    bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    svg, path {
        fill: #fff;
    }
    transition: opacity 0.1s ease;
    z-index: 99999999999;

    &:hover {
        opacity: 0.8;
    }
}

.skey-hero {
    width: 100vw;
    height: 70vh;
    // background: url("/image.png");
    // background-size: 90%;
    // background-position: center;
    // background-repeat: no-repeat;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .skey-hero-row {
        width: calc(100% - 250px);
        background-color: #eeeeee;
        border-radius: 250px;
        height: 250px;
        padding-left: 100px;

        .row-inner  {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 40px;

            .hero-brdr {
                width: 14px;
                height: 60%;
                background-color: #542E60;
                border-radius: 30px;
            }

            .hero-feature {
                height: 60%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                row-gap: 20px;
                width: calc(50% - 100px);

                > div {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    height: auto;
                    row-gap: 0;

                    h1 {
                        font-size: 24px;
                        color: #542E60;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    .skey-laptop-artwork {
        position: absolute;
        right: 80px;
        top: 50%;
        transform: translateY(-50%);

        svg {
            width: 600px;
        }
    }
}

.skey-browser-ext {
    width: 100%;
    height: 60vh;

    > .inner {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 60px;

        > div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            gap: 10px;

            h1 {
                font-size: 32px;
                color: #542E60;
                font-weight: 600;
            }

            p {
                font-size: 16px;
                color: rgba($color: #000000, $alpha: 0.4);
            }

            .chrome-str {
                width: 400px;
                height: 100px;
                // border: 1px solid #eeeeee;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                img {
                    width: 90%;
                }
            }
        }
    }
}

.skey-footer {
    width: 100%;
    background-color: #542E60;
    color: #ffffff;
    padding: 40px 20px;
    font-family: Arial, sans-serif;
  
    .inner {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
  
      .footer-column {
        flex: 1 1 150px;
        margin-bottom: 20px;
  
        h3 {
          font-size: 18px;
          margin-bottom: 15px;
        }
  
        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;
  
          li {
            margin-bottom: 8px;
  
            a {
              color: #ffffff;
              text-decoration: none;
              font-size: 14px;
  
              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  
    .footer-info {
      text-align: center;
      margin-top: 30px;
      font-size: 14px;
      line-height: 1.5;
      max-width: 800px;
      margin: 0 auto;
    }
  
    .copyright {
      text-align: center;
      margin-top: 20px;
      font-size: 12px;
      width: 100%;
    }
  }