.selectElement {
    width: auto;
    height: 44px;
    border: none;
    background-color: var(--color-bg);
    box-shadow: 0 0 0 1px var(--color-unnamed-3);
    border: none;
    padding: 0 20px;
    border-radius: 42px;
    cursor: pointer;

    display: inline-flex;
    display: -webkit-inline-flex;

    transition: background-color 0.1s ease,
        box-shadow 0.06s ease;

    &.jsText {
        font-family: 'Josefin sans', sans-serif;
        font-size: 18px;
    }


    white-space: nowrap;
    font-size: 16px;
    color: var(--color-primary);

    &.widthBlock {
        width: 100%;
    }

    &:hover {
        background: var(--color-unnamed-2);
    }

    &:focus {
        box-shadow: 0 0 0 3px var(--color-unnamed-1);
    }
}

option {
    height: 44px;
}


.select-control {
    width: 100%;
    height: 50px;
    border: 1px solid rgba(221, 221, 221, 1);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    border-radius: 30px;
    
    .selected {
        width: 100%;
        height: 100%;

        input {
            width: 100%;
            height: 100%;
            border: none;
            background-color: transparent;
            cursor: pointer;
            opacity: 1;
            position: static;
            opacity: 1;
            font-size: 16px;
            padding: 0 16px;
            padding-left: 22px;
        }
    }

    .extra {
        width: auto;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: 20px;

        > div {
            height: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
}