.shortkey {
  width: 100%;
  position: relative;
  // border-bottom: 1px solid var(--color-unnamed-7);
  &:hover {
    .title {
      text-decoration: underline;
    }
  }
  &.pinned_end {
    border-bottom: 1px solid #532d63;
  }

  > .inner-shortkey {
    width: 100%;
    height: 100%;
    display: flex;
    display: -webkit-flex;
    flex-direction: row;
    // justify-content: flex-start;
    justify-content: space-between;
    // align-items: center;
    align-items: flex-start;

    padding: 12px 0 0 0;

    > div {
      width: 100%;
      height: 100%;

      &.mainEarly {
        width: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        position: relative;
        bottom: -5px;
      }

      &.main {
        width: calc(100% - 80px);
        height: 100%;
        display: flex;
        display: -webkit-flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        > div {
          width: 100%;
          height: 100%;

          &.titleAndUrl {
            overflow: hidden;
            text-overflow: ellipsis;
            display: flex;
            display: -webkit-flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            // padding: 8px 0;

            > svg {
              min-width: 24px;
            }

            > span {
              max-width: 100%;
              text-overflow: ellipsis;
              overflow: hidden;

              &.title {
                font-size: 17px;
                color: var(--color-primary);
                cursor: pointer !important;

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }

          .url {
            font-size: 15px;
            color: var(--color-text);
          }
        }
      }

      &.options {
        width: auto;
        height: 100%;
        display: flex;
        display: -webkit-flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        /** Keep popup in border */
        position: relative;

        .hidden-select-sk-more {
          // display: none;
          position: absolute;
          user-select: none;
          width: 40px;
          height: 40px;
          background-color: transparent;
          opacity: 0;
          cursor: pointer;
        }
      }
    }
  }
}

.keywords {
  display: flex;
  display: -webkit-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-size: 15px;
  color: var(--color-text);
  .keyword {
    width: auto;
    height: auto;
    padding: 4px 12px;
    font-size: 13px;
    font-weight: 100;
    color: var(--color-bg);
    background-color: var(--color-primary);
    border-radius: 14px;
    margin-right: 8px;

    &:last-child {
      margin-right: 0;
    }

    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    &.with-close {
      padding-right: 26px;
    }

    color: var(--color-white);

    span {
      color: var(--color-white);
    }

    button {
      margin-left: 8px;
      background-color: transparent;
      border: none;
      display: -webkit-flex;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      width: 16px;
      height: 16px;
      border-radius: 20px;
      position: absolute;
      right: 4px;
      cursor: pointer;
      opacity: 0.3;

      &:hover {
        opacity: 0.6;
      }

      svg {
        width: 12px;
      }
    }
  }

  > span {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
}
