@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300;400;500&family=Inter:wght@400;500&display=swap');

.jsTextPrimary {
    // font-family: 'Josefin sans', sans-serif;
    font-family: 'Arial', sans-serif;
    color: var(--color-primary);
    font-size: 16px;
}

.jsTextBody {
    font-family: 'Arial', sans-serif;
    color: var(--color-text);
    font-size: 16px;
}

.textPrimary {
    font-family: 'Arial', sans-serif;
    color: var(--color-primary);
    font-size: 16px;
}

.textBody {
    font-family: 'Arial', sans-serif;
    color: var(--color-text);
    font-size: 16px;
}