/** Base colors (Default theme) **/
$color-primary: #5D366D;
$color-text: #969696;
$color-bg: #ffffff;
$color-bg-alt: #f5f5f5;
$color-bg-alt2: #f6f6f6;
$color-bg-inverted: #000000;

// used in button hover bg & focus
$color-unnamed-1: rgba(84, 46, 96, 0.06);

// used in button hover bg
$color-unnamed-2: rgba(238, 238, 238, 0.8);

// used at multiple places
$color-unnamed-3: rgba(0, 0, 0, 0.1);

// used in checkbox
$color-unnamed-4: rgba(93, 54, 109, 0.4);

// used in checkbox
$color-unnamed-5: rgb(93, 54, 109);

// used in dialog backfall
$color-unnamed-6: rgba(0, 0, 0, 0.5);

// used in shortkey bottom border
$color-unnamed-7: rgba(0, 0, 0, 0.06);

html {
  --color-primary: #5D366D;
  --color-text: #969696;
  --color-bg: #ffffff;

  --color-bg-alt: #f5f5f5;
  --color-bg-alt2: #f6f6f6;
  --color-bg-inverted: #000000;

  // used in button hover bg & focus
  --color-unnamed-1: rgba(84, 46, 96, 0.06);

  // used in button hover bg
  --color-unnamed-2: rgba(238, 238, 238, 0.8);

  // used at multiple places
  --color-unnamed-3: rgba(0, 0, 0, 0.1);

  // used in checkbox
  --color-unnamed-4: rgba(93, 54, 109, 0.4);

  // used in checkbox
  --color-unnamed-5: rgb(93, 54, 109);

  // used in dialog backfall
  --color-unnamed-6: rgba(0, 0, 0, 0.5);

  // used in shortkey bottom border
  --color-unnamed-7: rgba(0, 0, 0, 0.06);

  --color-white: #ffffff;
}

@media (prefers-color-scheme: light) {
  html {
    --color-primary: #5D366D;
    --color-text: #969696;
    --color-bg: #ffffff;

    --color-bg-alt: #f5f5f5;
    --color-bg-alt2: #f6f6f6;
    --color-bg-inverted: #000000;

    --color-white: #ffffff;

    // used in button hover bg & focus
    --color-unnamed-1: rgba(84, 46, 96, 0.06);

    // used in button hover bg
    --color-unnamed-2: rgba(238, 238, 238, 0.8);

    // used at multiple places
    --color-unnamed-3: rgba(0, 0, 0, 0.1);

    // used in checkbox
    --color-unnamed-4: rgba(93, 54, 109, 0.4);

    // used in checkbox
    --color-unnamed-5: rgb(93, 54, 109);

    // used in dialog backfall
    --color-unnamed-6: rgba(0, 0, 0, 0.5);

    // used in shortkey bottom border
    --color-unnamed-7: rgba(0, 0, 0, 0.06);
  }
}

// @media (prefers-color-scheme: dark) {
//   html {
//     --color-primary: #5D366D;
//     --color-text: #424146;
//     --color-bg: #181818;

//     --color-bg-alt: #0a0a0a;
//     --color-bg-alt2: #090909;
//     --color-bg-inverted: #ffffff;

//     --color-white: #ffffff;

//     // used in button hover bg & focus
//     --color-unnamed-1: rgba(84, 46, 96, 0.06);

//     // used in button hover bg
//     --color-unnamed-2: rgba(17, 17, 17, 0.8);

//     // used at multiple places
//     --color-unnamed-3: rgba(255, 255, 255, 0.1);

//     // used in checkbox
//     --color-unnamed-4: rgba(93, 54, 109, 0.4);

//     // used in checkbox
//     --color-unnamed-5: rgb(93, 54, 109);

//     // used in dialog backfall
//     --color-unnamed-6: rgba(73, 67, 67, 0.5);

//     // used in shortkey bottom border
//     --color-unnamed-7: rgba(255, 255, 255, 0.06);
//   }
// }

html.solidLight {
}

html.solidDark {
  --color-primary: #5D366D;
  --color-text: #969696;
  --color-bg: #181818;

  --color-bg-alt: #0a0a0a;
  --color-bg-alt2: #090909;
  --color-bg-inverted: #ffffff;

  // used in button hover bg & focus
  --color-unnamed-1: rgba(84, 46, 96, 0.06);

  // used in button hover bg
  --color-unnamed-2: rgba(17, 17, 17, 0.8);

  // used at multiple places
  --color-unnamed-3: rgba(255, 255, 255, 0.1);

  // used in checkbox
  --color-unnamed-4: rgba(93, 54, 109, 0.4);

  // used in checkbox
  --color-unnamed-5: rgb(93, 54, 109);

  // used in dialog backfall
  --color-unnamed-6: rgba(73, 67, 67, 0.5);

  // used in shortkey bottom border
  --color-unnamed-7: rgba(255, 255, 255, 0.06);
}

html.solidPurple {
  --color-primary: #5D366D;
  --color-text: #6f6c90;
  --color-bg: #ffd9fb;

  --color-bg-alt: #f5f5f5;
  --color-bg-alt2: #f6f6f6;
  --color-bg-inverted: #000000;

  --color-white: #ffffff;

  // used in button hover bg & focus
  --color-unnamed-1: rgba(84, 46, 96, 0.06);

  // used in button hover bg
  --color-unnamed-2: rgba(238, 238, 238, 0.8);

  // used at multiple places
  --color-unnamed-3: rgba(0, 0, 0, 0.1);

  // used in checkbox
  --color-unnamed-4: rgba(93, 54, 109, 0.4);

  // used in checkbox
  --color-unnamed-5: rgb(93, 54, 109);

  // used in dialog backfall
  --color-unnamed-6: rgba(0, 0, 0, 0.5);

  // used in shortkey bottom border
  --color-unnamed-7: rgba(0, 0, 0, 0.06);
}

html.solidBlue {
  --color-primary: #5D366D;
  --color-text: #969696;
  --color-bg: #d9e9ff;

  --color-bg-alt: #f5f5f5;
  --color-bg-alt2: #f6f6f6;
  --color-bg-inverted: #000000;

  --color-white: #ffffff;

  // used in button hover bg & focus
  --color-unnamed-1: rgba(84, 46, 96, 0.06);

  // used in button hover bg
  --color-unnamed-2: rgba(238, 238, 238, 0.8);

  // used at multiple places
  --color-unnamed-3: rgba(0, 0, 0, 0.1);

  // used in checkbox
  --color-unnamed-4: rgba(93, 54, 109, 0.4);

  // used in checkbox
  --color-unnamed-5: rgb(93, 54, 109);

  // used in dialog backfall
  --color-unnamed-6: rgba(0, 0, 0, 0.5);

  // used in shortkey bottom border
  --color-unnamed-7: rgba(0, 0, 0, 0.06);
}

html.solidGreen {
  --color-primary: #5D366D;
  --color-text: #969696;
  --color-bg: #d9fff2;

  --color-bg-alt: #f5f5f5;
  --color-bg-alt2: #f6f6f6;
  --color-bg-inverted: #000000;

  --color-white: #ffffff;

  // used in button hover bg & focus
  --color-unnamed-1: rgba(84, 46, 96, 0.06);

  // used in button hover bg
  --color-unnamed-2: rgba(238, 238, 238, 0.8);

  // used at multiple places
  --color-unnamed-3: rgba(0, 0, 0, 0.1);

  // used in checkbox
  --color-unnamed-4: rgba(93, 54, 109, 0.4);

  // used in checkbox
  --color-unnamed-5: rgb(93, 54, 109);

  // used in dialog backfall
  --color-unnamed-6: rgba(0, 0, 0, 0.5);

  // used in shortkey bottom border
  --color-unnamed-7: rgba(0, 0, 0, 0.06);
}

html.solidOrange {
  --color-primary: #5D366D;
  --color-text: #969696;
  --color-bg: #ffead9;

  --color-bg-alt: #f5f5f5;
  --color-bg-alt2: #f6f6f6;
  --color-bg-inverted: #000000;

  --color-white: #ffffff;

  // used in button hover bg & focus
  --color-unnamed-1: rgba(84, 46, 96, 0.06);

  // used in button hover bg
  --color-unnamed-2: rgba(238, 238, 238, 0.8);

  // used at multiple places
  --color-unnamed-3: rgba(0, 0, 0, 0.1);

  // used in checkbox
  --color-unnamed-4: rgba(93, 54, 109, 0.4);

  // used in checkbox
  --color-unnamed-5: rgb(93, 54, 109);

  // used in dialog backfall
  --color-unnamed-6: rgba(0, 0, 0, 0.5);

  // used in shortkey bottom border
  --color-unnamed-7: rgba(0, 0, 0, 0.06);
}

html.solidYellow {
  --color-primary: #5D366D;
  --color-text: #969696;
  --color-bg: #ffffd9;

  --color-bg-alt: #f5f5f5;
  --color-bg-alt2: #f6f6f6;
  --color-bg-inverted: #000000;

  --color-white: #ffffff;

  // used in button hover bg & focus
  --color-unnamed-1: rgba(84, 46, 96, 0.06);

  // used in button hover bg
  --color-unnamed-2: rgba(238, 238, 238, 0.8);

  // used at multiple places
  --color-unnamed-3: rgba(0, 0, 0, 0.1);

  // used in checkbox
  --color-unnamed-4: rgba(93, 54, 109, 0.4);

  // used in checkbox
  --color-unnamed-5: rgb(93, 54, 109);

  // used in dialog backfall
  --color-unnamed-6: rgba(0, 0, 0, 0.5);

  // used in shortkey bottom border
  --color-unnamed-7: rgba(0, 0, 0, 0.06);
}

//// Colors
